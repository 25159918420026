import { NuxtError } from 'nuxt/app';
import { AxosError } from '~/types/axos-api';

interface APIV2Error {
	code?: string;
	message: string;
	resourceId?: string;
}

export default function (
	error?: Ref<AxosError> | Ref<null> | AxosError | null | any
) {
	// Pre-defined error helpers
	const notFoundError = (message?: string) => {
		const errorData: Partial<NuxtError> = {
			statusCode: 404,
			statusMessage: message ?? '',
			fatal: true,
		};

		return createError(errorData);
	};

	const forbiddenError = (message?: string) => {
		const errorData: Partial<NuxtError> = {
			statusCode: 403,
			statusMessage: message ?? 'Du har ikke tilgang til denne siden',
			fatal: true,
		};

		return createError(errorData);
	};

	const onError = createEventHook<AxosError>();
	const onNotFound = createEventHook<AxosError>();
	const onUnhandledError = createEventHook<AxosError>();

	if (isRef(error)) {
		watch(
			() => error.value,
			(errorValue) => {
				if (errorValue) {
					console.log('error value', errorValue.response);

					if (errorValue?.response) {
						triggerErrorAPIV2(
							JSON.parse(errorValue.response),
							errorValue.headers
						);
					} else {
						triggerError(errorValue);
					}
				}
			}
		);
	} else if (error) {
		triggerError(error);
	}

	function triggerError(errorData: AxosError) {
		if (!errorData) return;

		const code = (errorData.extensions as any)?.code;

		if (code === 'ENTITY_NOT_FOUND') {
			if (numListenersNotFound > 0) {
				onNotFound.trigger(errorData);
				return;
			} else {
				// Default 404 action
				throw notFoundError(errorData?.message as string | undefined);
			}
		}

		if (numListenersUnhandled > 0) {
			onUnhandledError.trigger(errorData);
			return;
		}

		// Fallback error action
		$error(errorData);
	}

	function triggerErrorAPIV2(errorData: APIV2Error, headers: object) {
		if (!errorData) return;

		// Fallback error action
		$error(errorData);
	}

	let numListenersNotFound = 0;
	let numListenersUnhandled = 0;

	// Wrap the original onNotFound.on in another function to keep track of listeners
	const wrappedOnNotFound = (listener: (param: AxosError) => void) => {
		numListenersNotFound++;
		onNotFound.on(listener);
		return () => {
			numListenersNotFound--;
			onNotFound.off(listener);
		};
	};

	const wrappedOnUnhandled = (listener: (param: AxosError) => void) => {
		numListenersUnhandled++;
		onUnhandledError.on(listener);
		return () => {
			numListenersUnhandled--;
			onUnhandledError.off(listener);
		};
	};

	return {
		onNotFoundError: wrappedOnNotFound,
		onError: onError.on,
		onUnhandledError: wrappedOnUnhandled,

		notFoundError,
		forbiddenError,
	};
}
