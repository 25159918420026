<template>
	<Teleport to="body">
		<div
			v-if="notifications.length"
			aria-live="assertive"
			class="pointer-events-none fixed inset-0 flex content-end items-end px-4 py-16"
		>
			<div class="flex w-full flex-col items-end space-y-4 sm:items-end">
				<div
					v-for="notification in notifications"
					:key="notification.id"
					class="pointer-events-auto flex w-full max-w-md"
				>
					<NotificationInboxItem
						class="w-full bg-white shadow-lg"
						:notification="notification"
					/>
				</div>
			</div>
		</div>
	</Teleport>
</template>
<script setup lang="ts">
import { NotificationItem } from '~/codegen/axos-client';

const { onNotificationReceived } = useNotificationHub();

const notifications = useState<NotificationItem[]>(
	'appNotifications',
	() => []
);

onNotificationReceived((notification) => {
	showNotification(notification);
});

function showNotification(entityEvent: NotificationItem, timeout = 7500) {
	if (notifications.value.length > 2) {
		notifications.value.shift();
	}

	notifications.value.push(entityEvent);
	setTimeout(() => removeNotification(entityEvent.id), timeout);
}

function removeNotification(id: number) {
	notifications.value = notifications.value.filter(
		(notification) => notification.id !== id
	);
}
</script>
