import { CreateIdeaInput } from '~/types/axos-api';

export default function () {
	const mutation = gql`
		mutation createIdea(
			$input: CreateIdeaInput!
		) {
			createIdea(input: $input) {
				boolean
				${useErrorFragment()}
			}
		}
	`;

	const { mutateAsync, error, ...args } =
		useLegacyMutation<CreateIdeaInput>(mutation);
	useErrorHandler(error);

	return {
		...args,
		create: mutateAsync,
	};
}
