import { NuxtError } from '#app';
import { AxosCapabilities, Query } from '~/types/axos-api';

export default defineNuxtRouteMiddleware(async (to, from) => {
	const { IS_DEV, IS_TEST } = useEnvironment();
	if (IS_TEST) {
		return;
	}

	if (IS_DEV && to.path === '/setup/client') {
		return;
	}

	const app = useNuxtApp();

	// Cache capability access state in-memory
	const capabilityAccessState = useState<AxosCapabilities[] | null>(
		'capabilityAccess'
	);

	if (to.meta?.capabilityAccess) {
		const query = gql`
			query getCapabilitiesForMe {
				capabilitiesForMe
			}
		`;

		try {
			let capabilityAccesses: AxosCapabilities[];

			if (
				!capabilityAccessState.value ||
				capabilityAccessState.value.length === 0
			) {
				const res = await app.$graphql.request<Query>(query);
				const availableCapabilities = res.capabilitiesForMe;
				capabilityAccesses = availableCapabilities;
				capabilityAccessState.value = availableCapabilities;
			} else {
				capabilityAccesses = capabilityAccessState.value;
			}

			let hasCapabilityAccess = false;

			// If capabilities is array, check that at least one of the capability Ids from array is present in availableCapabilities
			if (Array.isArray(to.meta.capabilityAccess)) {
				hasCapabilityAccess = to.meta.capabilityAccess.some(
					(capability) =>
						capabilityAccesses.some((am) => am === capability)
				);
			} else {
				hasCapabilityAccess = capabilityAccesses.some(
					(am) => am === to.meta.capabilityAccess
				);
			}

			if (!hasCapabilityAccess) {
				const noAccessError: Partial<NuxtError> = {
					statusCode: 403,
					message: 'You do not have access to this resource',
					fatal: true,
				};

				return createError(noAccessError);
			}
		} catch (error) {
			console.error(
				'Failed to fetch capabilities for user in middleware',
				error
			);
		}
	}
});
