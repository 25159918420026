<template>
	<section
		class="fade-in-top col-span-12 flex"
		:class="{
			'mx-auto w-full max-w-6xl': width === 'narrow',
			'flex-col': !image,
			'flex-row-reverse ': image,
		}"
	>
		<div
			v-if="title"
			class="mb-1 mt-2 flex min-h-9 items-center justify-between"
		>
			<div
				@click="togglable ? (expanded = !expanded) : null"
				:class="{
					'cursor-pointer pr-1': togglable,
				}"
			>
				<span
					v-if="titlePrefix"
					class="text-xs font-medium text-slate-500"
				>
					{{ titlePrefix }}
				</span>
				<h2
					v-if="title"
					class="text-base font-semibold leading-6 text-slate-600"
				>
					{{ title }}
				</h2>
				<p v-if="description" class="mt-1 text-sm text-slate-500">
					{{ description }}
				</p>
			</div>
			<Icon
				v-if="togglable"
				:name="ICON_CHEVRON_RIGHT"
				class="mr-auto size-5 text-slate-400 transition-transform"
				:class="{
					'rotate-90': expanded,
					'cursor-pointer': togglable,
				}"
				aria-hidden="true"
				@click="togglable ? (expanded = !expanded) : null"
			/>
			<template v-if="expanded">
				<slot name="action"></slot>
			</template>
		</div>
		<div
			v-if="expanded"
			class="flex flex-col"
			:class="{
				[props.class]: !!$props.class,
				'col-span-12 grid grid-cols-12': hasCols,

				/* Padding */
				'px-6 py-4 2xl:px-10 2xl:py-6': !noPadding,
				/* Shadow */
				'border border-slate-200': !noShadow,
				'rounded-md bg-white': !noBg,
				'w-2/3 rounded-l-none border-l-0': image,
			}"
		>
			<slot></slot>
		</div>
		<div
			v-if="image"
			class="w-1/3 self-stretch rounded-l-md bg-cover bg-center"
			:style="`background-image: url(${image})`"
		></div>
	</section>
</template>
<script setup lang="ts">
interface Props {
	titlePrefix?: string;
	title?: string;
	description?: string;

	hasCols?: boolean;
	image?: string;

	class?: any;
	noPadding?: boolean;
	noShadow?: boolean;
	noBg?: boolean;

	/**
	 * narrow, default, full
	 */
	width?: string;
	togglable?: boolean;
	expanded?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	togglable: true,
	expanded: true,
});
const expanded = ref(props.expanded);
</script>
