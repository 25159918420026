import { useQuery } from '@tanstack/vue-query';
import { AxosCapabilities } from '~/types/axos-api';

export default async function (capability: AxosCapabilities) {
	const query = gql`
		query GetCapabilitiesForMe {
			capabilitiesForMe
		}
	`;

	const { data, suspense } = useQuery({
		queryKey: ['capabilitiesForMeAsync'],
		queryFn: useGQLRequest(query),
		staleTime: Number.POSITIVE_INFINITY,
		select: (data) => data.capabilitiesForMe,
	});

	await suspense();

	const hasCapability = data.value?.includes(capability) ?? false;

	return hasCapability;
}
