import {
	dehydrate,
	DehydratedState,
	hydrate,
	MutationCache,
	Query,
	QueryClient,
	VueQueryPlugin,
	VueQueryPluginOptions,
} from '@tanstack/vue-query';

export default defineNuxtPlugin((nuxt) => {
	const vueQueryState = useState<DehydratedState | null>('vue-query');

	const queryClient = new QueryClient({
		defaultOptions: {
			// TODO: For testing purposes, remove this
			queries: {
				// staleTime: Infinity,
				refetchOnWindowFocus: false,
				staleTime: 2 * 60 * 1000,
				retry: false,
			},
			mutations: {
				retry: false,
			},
		},

		// Automatically invalidate all queries when a mutation is successful
		mutationCache: new MutationCache({
			onSuccess: (_data, _variables, _context, mutation) => {
				const nonStaticQueries = (query: Query) => {
					const defaultStaleTime =
						queryClient.getQueryDefaults(query.queryKey)
							.staleTime ?? 0;
					const staleTimes = query.observers
						.map((observer) => observer.options.staleTime)
						.filter((staleTime) => staleTime !== undefined);

					const staleTime =
						query.getObserversCount() > 0 && staleTimes.length > 0
							? Math.min(...staleTimes)
							: defaultStaleTime;

					const shouldInvalidate =
						staleTime !== Number.POSITIVE_INFINITY;

					return shouldInvalidate;
				};

				queryClient.invalidateQueries({
					predicate: nonStaticQueries,
				});
			},
		}),
	});

	const options: VueQueryPluginOptions = {
		queryClient,
	};

	nuxt.vueApp.use(VueQueryPlugin, options);

	if (process.server) {
		nuxt.hooks.hook('app:rendered', () => {
			vueQueryState.value = dehydrate(queryClient);
		});
	}

	if (process.client) {
		nuxt.hooks.hook('app:created', () => {
			hydrate(queryClient, vueQueryState.value);
		});
	}
});
