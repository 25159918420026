<template>
	<nuxt-img v-show="loaded" @load="loaded = true" class="" v-bind="$attrs" />
	<div
		v-show="!loaded"
		:class="`animate-pulse rounded-full bg-slate-50 dark:bg-slate-300 ${$attrs.class}`"
	></div>
</template>
<script setup lang="ts">
const loaded = ref(false);
</script>
