import { AxosClient } from '~/codegen/axos-client';

export default function () {
	const config = useRuntimeConfig();
	const { fetchWithDefaults } = useHttpClient();

	const api = new AxosClient(config.public.BFF_URL + '/api', {
		fetch(url, init) {
			return fetchWithDefaults(url, init);
		},
	});

	return { api };
}
