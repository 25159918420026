<template>
	<VueDatePicker
		v-bind="context.attrs"
		:id="context.node.props.id"
		:uid="context.node.props.id"
		:disabled="context.disabled"
		:model-value="internalValue"
		:text-input="{
			format: enableTimePicker
				? ['dd.MM.yyyy HH:mm', 'dd/MM/yyyy HH:mm']
				: ['dd.MM.yyyy', 'dd/MM/yyyy'],
		}"
		@update:modelValue="handleInput"
		:format="enableTimePicker ? 'dd.MM.yyyy HH:mm' : 'dd.MM.yyyy'"
		:clearable="DISABLE_CLEARABLE"
		auto-apply
		:locale="currentLanguageId != 1 ? 'en-US' : 'no'"
		:time-picker-inline="enableTimePicker"
		:teleport="true"
	/>
</template>
<script setup lang="ts">
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { format, parse } from 'date-fns';
import { fromZonedTime, toZonedTime } from 'date-fns-tz';

const { currentLanguageId } = useAppLanguage();

// TODO: Remove as soon as the following PR is approved
// https://github.com/Vuepic/vue-datepicker/pull/962
const DISABLE_CLEARABLE = false;

// UTC
const internalValue = computed(() =>
	typeof props.context?._value === 'string'
		? fromZonedTime(
				parse(
					props.context?._value,
					props.context.node?.props?.valueFormat ?? 'dd.MM.yyyy',
					new Date()
				),
				'UTC'
		  )
		: props.context?._value
);

const props = defineProps<{ context: any }>();

const enableTimePicker = props.context.node?.props?.timePicker ?? false;

if (props.context?._value) {
	const date = new Date(props.context?._value);
	const formattedDate = format(
		date,
		props?.context.node?.props?.valueFormat ?? 'dd.MM.yyyy'
	);

	props.context?.node.input(formattedDate);
} else if (props.context?.todayInitial) {
	props.context?.node.input(new Date(), false);
}

function handleInput(value: string) {
	if (!value) {
		props.context?.node.input(null);
		return;
	}

	const date = toZonedTime(value, 'UTC');

	const formattedDate = format(
		date,
		props?.context.node?.props?.valueFormat ?? 'dd.MM.yyyy'
	);

	props.context?.node.input(formattedDate);
}
</script>

<style>
.dp__menu {
	@apply border-slate-200 shadow-lg;
}

.dp__instance_calendar {
	@apply text-sm;
	font-family: 'figtree', sans-serif;
}

.dp__calendar_header_separator {
	@apply bg-slate-200;
}

.dp__input {
	@apply !rounded border border-slate-200 !bg-white pl-2.5 text-sm text-slate-700 !shadow-none !outline-none hover:!border-slate-200 focus:border-slate-200 focus:ring-2  focus:ring-primary-500  focus:ring-offset-0;

	font-family: 'figtree', sans-serif;

	&[readonly] {
		@apply text-slate-400 hover:cursor-not-allowed hover:border-inherit focus:border-inherit focus:bg-inherit focus:ring-inherit;
	}
}

.dp__input_icon,
.dp__instance_calendar,
.dp__calendar_item {
	@apply text-slate-500;
}

.dp__input_icon {
	@apply left-auto right-0;
}

.dp__month_year_row {
	@apply m-1;
}

.dp__disabled,
.dp__disabled + div .dp__input_icon {
	@apply fill-slate-400 text-slate-400 opacity-75;
}

.formkit-outer[data-type='datepicker'][data-invalid='true'] .dp__input {
	@apply ring-2 ring-red-500;
}
</style>
