import { NuxtError } from 'nuxt/app';
import { AxosError } from '~/types/axos-api';

export function $success(messsage: string, timeout?: number) {
	const { success } = useToasts();
	success(messsage, timeout);
}

export function $info(messsage: string, timeout?: number) {
	const { info } = useToasts();
	info(messsage, timeout);
}

export function $error(
	errorData: AxosError | NuxtError | string | any,
	timeout?: number
) {
	const { error } = useToasts();
	error(errorData, timeout);
}

export function $warning(messsage: string, timeout?: number) {
	const { warning } = useToasts();
	warning(messsage, timeout);
}
