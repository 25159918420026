export default function () {
	const mutation = gql`
        mutation clearAccessCache {
            clearAccessCache {
                boolean
                ${useErrorFragment()}
            }
        }
    `;

	const { mutateAsync, ...args } = useLegacyMutation<any>(mutation);

	return {
		...args,
		clearAccessCache: mutateAsync,
	};
}
