import * as signalR from '@microsoft/signalr';

export default defineNuxtPlugin(async () => {
	const config = useRuntimeConfig();

	const { data } = await useFetch<any>('/token', {
		baseURL: config.public.BFF_URL,
		credentials: 'include',
	});

	const token = data.value?.accessToken;

	const connection = new signalR.HubConnectionBuilder()
		.withUrl(`${config.public.BFF_URL}/ws/hubs/notification`, {
			accessTokenFactory: () => token,
		})
		.build();

	return {
		provide: {
			signalR: connection,
		},
	};
});
