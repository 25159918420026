import { useQuery } from '@tanstack/vue-query';
import { AuthClientDto } from '~/types/axos-api';

export default function () {
	const currentClientId = useState<number | null>(
		'currentClientId',
		() => null
	);

	const config = useRuntimeConfig();

	const { data, ...args } = useQuery({
		queryKey: ['availableClients'],
		queryFn: async () => {
			const { data, error } = await useFetch<AuthClientDto[]>(
				'/availableClients',
				{
					baseURL: config.public.BFF_URL + '/api/auth',
					credentials: 'include',
				}
			);

			return data.value;
		},
		staleTime: Number.POSITIVE_INFINITY,
	});

	async function selectClient(clientId: number) {
		await $fetch('/select-client', {
			baseURL: config.public.BFF_URL,
			method: 'POST',
			credentials: 'include',
			body: JSON.stringify({ clientId }),
		});

		currentClientId.value = clientId;
	}

	const currentClient = computed(() =>
		data.value?.find((client) => client.id === currentClientId.value)
	);

	return {
		clients: data,
		selectClient,
		currentClient,
		currentClientId,
		...args,
	};
}
