import { useQueryClient } from '@tanstack/vue-query';

export default function () {
	const config = useRuntimeConfig();
	const route = useRoute();

	const queryClient = useQueryClient();

	function login() {
		const loginUrl = new URL(config.public.BFF_URL);
		loginUrl.pathname = '/login';

		const currentRoute = route.fullPath;
		loginUrl.searchParams.append('redirectUrl', currentRoute);

		return navigateTo(loginUrl.toString(), { external: true });
	}

	async function logout() {
		const res = await $fetch<{ logoutUrl: string }>('logout', {
			baseURL: config.public.BFF_URL,
			method: 'POST',
			credentials: 'include',
		});

		queryClient.clear();

		window.location.href = res.logoutUrl;
	}

	return {
		login,
		logout,
	};
}
