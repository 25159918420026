import { FormKitTypeDefinition } from '@formkit/core';
import { defaultIcon, password } from '@formkit/inputs';

/**
 * Axos password input definition.
 * @public
 */
export const customPassword: FormKitTypeDefinition = {
	...password,
	features: [
		defaultIcon('suffix', 'eye'),
		(node) => {
			node.props.onSuffixIconClick = () => {
				node.props.type =
					node.props.type === 'password' ? 'text' : 'password';

				node.props.suffixIcon =
					node.props.type === 'password' ? 'eye' : 'eyeClosed';
			};
		},
	],
};
