import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import {
	useMutation as useMutationFn,
	UseMutationOptions,
} from '@tanstack/vue-query';
import { RequestDocument } from 'graphql-request';
import { AxosError, Mutation } from '~/types/axos-api';
import { createEventHook } from '@vueuse/core';

interface QueryWrapperOptions {
	/**
	 * By default the variables in wrapped in an object with the key "input".
	 * ```js
	 * { "input": variables }
	 * ```
	 * By setting this to `false` the variables will be passed directly.
	 */
	inputWrapper?: boolean;
	queryOptions?: UseMutationOptions<Mutation, unknown, unknown, unknown>;
}

/**
 * @deprecated Use `useMutation` directly from @tanstack/vue-query instead
 */
export default function useLegacyMutation<
	TVariables = unknown,
	TData = Mutation
>(
	query: MaybeRef<RequestDocument | TypedDocumentNode<Mutation, TVariables>>,
	options?: QueryWrapperOptions
) {
	const app = useNuxtApp();

	const onSuccess = createEventHook<TData>();
	const onError = createEventHook<any>();

	const optionsWithDefaults = {
		inputWrapper: options?.inputWrapper ?? true,
		queryOptions: options?.queryOptions ?? undefined,
	};

	const mutation = useMutationFn<TData, unknown, TVariables>({
		...(optionsWithDefaults?.queryOptions as any),
		mutationFn: (variables) => {
			const finalVariables =
				optionsWithDefaults.inputWrapper && variables
					? { input: variables }
					: variables;

			return app.$graphql.request<any, any>(
				unref(query),
				finalVariables,
				undefined
			);
		},
		onSuccess: (data, variables, context) => {
			onSuccess.trigger(data);
		},
		onError: (error, variables, context) => {
			onError.trigger(error);
		},
	});

	const { pathsToCamelCase } = useErrorUtils();

	const mutationErrors = computed<AxosError[]>(() => {
		const mutationErrors = (mutation?.error as Ref<any>)?.value?.data
			?.errors;
		if (!mutationErrors) return null;

		return mutationErrors.map(pathsToCamelCase);
	});

	const mutationError = computed<AxosError>(() => {
		const mutationError = (mutation?.error as Ref<any>)?.value?.data;
		if (!mutationError) return null;

		return mutationError;
	});

	return {
		...mutation,
		errors: mutationErrors,
		error: mutationError,
		onSuccess: onSuccess.on,
		onError: onError.on,
	};
}
