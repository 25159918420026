// Default icon for modules

// HR
export const ICON_HR = 'ph:user-square-duotone';
export const ICON_EMPLOYEE = 'ph:user-duotone';
export const ICON_EMPLOYEES = 'ph:users-duotone';
export const ICON_MANAGER = 'ph:user-circle-plus-duotone';
export const ICON_ABSENCE = 'ph:calendar-duotone';
export const ICON_ABSENCE_CALENDAR = 'ph:calendar-duotone';
export const ICON_ABSENCE_APPROVAL = 'ph:check-circle-duotone';
export const ICON_ABSENCE_BALANCES = 'ph:scales-fill';
export const ICON_EMPLOYMENT = 'ph:briefcase-duotone';
export const ICON_ALLERGIES = 'ph:tag-duotone';
export const ICON_USER = 'ph:user-duotone';
export const ICON_INFO = 'ph:info-duotone';
export const ICON_EDUCATION = 'ph:student-duotone';
export const ICON_COMPETENCE = 'ph:trophy-duotone';
export const ICON_CERTIFICATE = 'ph:identification-card-duotone';
export const ICON_ADDRESS = 'ph:map-pin-duotone';
export const ICON_FAMILY = 'ph:heart-duotone';
export const ICON_DEVICE = 'ph:desktop-duotone';
export const ICON_INSURANCE = 'ph:shield-check-duotone';
export const ICON_BENEFIT = 'ph:star-duotone';
export const ICON_DEPARTMENT = 'ph:storefront-duotone';
export const ICON_OFFICE = 'ph:building-duotone';
export const ICON_TEAM = 'ph:users-duotone';
export const ICON_OKR = 'ph:target-duotone';
export const ICON_POSITION = 'ph:user-list-duotone';

// CRM
export const ICON_CRM = 'ph:briefcase-duotone';
export const ICON_CONTACT = 'ph:user-circle-duotone';
export const ICON_ACCOUNT = 'ph:buildings-duotone';
export const ICON_MEETING = 'ph:chats-duotone';
export const ICON_SOCIAL = 'ph:chat-circle-dots-duotone';
export const ICON_PRODUCT = 'ph:basket-duotone';
export const ICON_INDUSTRY = 'ph:briefcase-duotone';
export const ICON_PROJECT = 'ph:list-checks-duotone';

// Organisation
export const ICON_ORGANISATION = 'ph:building-office-duotone';
export const ICON_RESPONSIBILITY = 'ph:files-duotone';

// Idea
export const ICON_IDEA = 'ph:megaphone-duotone';

// Core
export const ICON_ADMIN = 'ph:gear-duotone';
export const ICON_HISTORY = 'ph:clock-counter-clockwise-duotone';
export const ICON_FILES = 'ph:files-duotone';
export const ICON_ACCESS = 'ph:users-duotone';

// Sales
export const ICON_SALES = 'ph:handshake-duotone';
export const ICON_LEAD = 'ph:target-duotone';
export const ICON_OPPORTUNITY = 'ph:chart-line-up-duotone';
export const ICON_QUOTE = 'ph:currency-dollar-duotone';
export const ICON_INVOICE = 'ph:receipt-duotone';

// Finance
export const ICON_FINANCE = 'ph:currency-circle-dollar-duotone';

export const ICON_ACTIVITY = 'ph:chat-circle-dots-duotone';
// General
export const ICON_BACK = 'heroicons:chevron-left-20-solid';
export const ICON_CHEVRON_DOWN = 'heroicons:chevron-down-20-solid';
export const ICON_CHEVRON_DOWN_DOUBLE =
	'heroicons:chevron-double-down-20-solid';
export const ICON_CHEVRON_UP = 'heroicons:chevron-up-20-solid';
export const ICON_CHEVRON_UP_DOUBLE = 'heroicons:chevron-double-up-20-solid';
export const ICON_CHEVRON_UP_DOWN = 'heroicons:chevron-up-down-20-solid';
export const ICON_CHEVRON_LEFT = 'heroicons:chevron-left-20-solid';
export const ICON_CHEVRON_LEFT_DOUBLE =
	'heroicons:chevron-double-left-20-solid';
export const ICON_CHEVRON_RIGHT = 'heroicons:chevron-right-20-solid';
export const ICON_CHEVRON_RIGHT_DOUBLE =
	'heroicons:chevron-double-right-20-solid';
export const ICON_TRASH = 'ph:trash-duotone';
export const ICON_SEARCH = 'ph:magnifying-glass-duotone';
export const ICON_FILTER = 'ph:funnel-duotone';
export const ICON_COG = 'ph:gear-six-duotone';
export const ICON_COG_FILLED = 'ph:gear-six-fill';
export const ICON_SETTINGS = ICON_COG;
export const ICON_EDIT = 'ph:pencil-duotone';
export const ICON_ADD = 'ph:plus-circle-duotone';
export const ICON_ADD_FILLED = 'ph:plus-circle-fill';
export const ICON_CLOSE = 'ph:x-bold';
export const ICON_CHECK = 'ph:check-bold';
export const ICON_WARNING = 'ph:warning-duotone';
export const ICON_WARNING_CIRCLE = 'ph:warning-circle-duotone';
export const ICON_ERROR = 'ph:x-circle-duotone';
export const ICON_SUCCESS = 'ph:check-circle-duotone';
export const ICON_LINK = 'ph:link-duotone';
export const ICON_LINK_EXTERNAL = 'ph:arrow-square-out-duotone';
export const ICON_CALENDAR = 'ph:calendar-duotone';
export const ICON_NOTIFICATION = 'ph:bell-duotone';
export const ICON_EMAIL = 'ph:envelope-duotone';
export const ICON_PHONE = 'ph:phone-duotone';
export const ICON_CELLULAR = 'ph:device-mobile-duotone';
export const ICON_LOCATION = 'ph:map-pin-duotone';
export const ICON_NUMBERING = 'ph:hash-duotone';
export const ICON_NUMBER = 'ph:hash-duotone';
export const ICON_CURRENCY_DOLLAR = 'ph:currency-circle-dollar-duotone';
export const ICON_COPY = 'ph:copy-simple-duotone';
export const ICON_DEVELOPER = 'ph:terminal-window-duotone';
export const ICON_X_CIRCLE = 'ph:x-circle-duotone';
export const ICON_X = 'ph:x-bold';
export const ICON_CHECK_CIRCLE = 'ph:check-circle-duotone';
export const ICON_IMPERSONATE = 'ph:user-switch-duotone';
export const ICON_USER_SHARE = 'ph:share-fat-duotone';
export const ICON_ELLIPSIS_VERTICAL = 'ph:dots-three-vertical-bold';
export const ICON_FILTER_ADD = 'ph:stack-plus-duotone';
export const ICON_ARROW_UP = 'ph:arrow-up-bold';
export const ICON_ARROW_DOWN = 'ph:arrow-down-bold';
export const ICON_ARROW_RIGHT = 'ph:arrow-right-bold';
export const ICON_ARROW_LEFT = 'ph:arrow-left-bold';
export const ICON_ARROWS_PATH = 'ph:arrow-counter-clockwise-duotone';
export const ICON_STAR = 'ph:star-duotone';
export const ICON_STAR_FILLED = 'ph:star-fill';
export const ICON_EYE = 'ph:eye-duotone';
export const ICON_EYE_SLASH = 'ph:eye-slash-duotone';
export const ICON_LOCK = 'ph:lock-simple-duotone';
export const ICON_UPLOAD = 'ph:cloud-arrow-up-duotone';
export const ICON_UPLOAD_ALT = 'ph:upload-duotone';
export const ICON_LIST = 'ph:list-duotone';
export const ICON_LIST_SEARCH = 'ph:list-magnifying-glass-duotone';
export const ICON_BARS = ICON_LIST;
export const ICON_TEXT_ALIGN_LEFT = 'ph:text-align-left';
export const ICON_TEXT_ALIGN_CENTER = 'ph:text-align-center';
export const ICON_TEXT_ALIGN_RIGHT = 'ph:text-align-right';
export const ICON_TEXT_ALIGN_JUSTIFY = 'ph:text-align-justify';
export const ICON_SAVE = 'ph:floppy-disk-duotone';
export const ICON_SEND = 'ph:paper-plane-tilt-duotone';
export const ICON_REOPEN = 'ph:arrows-counter-clockwise-duotone';
export const ICON_SWAP = 'ph:arrows-left-right-duotone';
export const ICON_DOWNLOAD = 'ph:download-duotone';
export const ICON_GLOBE = 'ph:globe-duotone';
export const ICON_LANGUAGE = ICON_GLOBE;
export const ICON_PUZZLE_PIECE = 'ph:puzzle-piece-duotone';
export const ICON_ENVELOPE_OPEN = 'ph:envelope-open-duotone';
export const ICON_HOME = 'ph:house-duotone';
export const ICON_HEART = 'ph:heart-duotone';
export const ICON_TEXT = 'ph:text-t';
export const ICON_RELATION = 'ph:shuffle-duotone';
export const ICON_NOTE = 'ph:note-duotone';
export const ICON_TROPHY = 'ph:trophy-duotone';
export const ICON_PALETTE = 'ph:palette-duotone';
export const ICON_COLOR = ICON_PALETTE;

// Power Editor
export const ICON_H1 = 'ph:text-h-one-bold';
export const ICON_H2 = 'ph:text-h-two-bold';
export const ICON_H3 = 'ph:text-h-three-bold';
export const ICON_LIST_BULLETS = 'ph:list-bullets-bold';
export const ICON_LIST_NUMBERS = 'ph:list-numbers-bold';
export const ICON_UNDO = 'ph:arrow-counter-clockwise-bold';
export const ICON_REDO = 'ph:arrow-clockwise-bold';
export const ICON_IMAGE = 'ph:image-duotone';
export const ICON_ITALIC = 'ph:text-italic-bold';
export const ICON_BOLD = 'ph:text-b-bold';

// AI
export const ICON_SPARKLES = 'ph:sparkle-duotone';
export const ICON_WRENCH = 'ph:wrench-bold';
export const ICON_MICROPHONE = 'ph:microphone-duotone';
export const ICON_TRANSLATE = 'ph:translate-duotone';

// External and integrations
export const ICON_CAMPAIGN_MONITOR = 'tabler:brand-campaignmonitor';
