export default function () {
	return `
		errors {
			... on AxosError {
				message
				resourceId
				code
				path
			}
		}
	`;
}
