export default function () {
	const config = useRuntimeConfig();
	const { impersonatingId } = useImpersonate();

	async function fetchWithDefaults(url: RequestInfo, init?: RequestInit) {
		const finalUrl = url.toString().startsWith('http')
			? url
			: config.public.BFF_URL + '/api' + url;

		const headers = init?.headers || {};

		// @ts-ignore
		headers['Content-Type'] = 'application/json';

		// @ts-ignore
		headers['Accept'] = 'application/json';

		const result = await fetch(finalUrl, {
			method: 'GET',
			...init,
			credentials: 'include',
			headers: impersonatingId.value
				? {
						...headers,
						'Impersonate-Id': impersonatingId.value,
				  }
				: headers,
		});

		if (!result.ok) {
			const error = await result.json();
			throw createError({
				message:
					error?.resourceId ?? error?.message ?? 'Error from API',
				data: error,
			});
		}

		return result;
	}

	return {
		fetchWithDefaults,
	};
}
