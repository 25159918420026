function useSidebar() {
	const breakpoints = useBreakpoints({ lg: 1024 });
	const isLargeScreen = breakpoints.greater('lg');
	const sidebarOpen = useState('sidebarOpen', () => isLargeScreen);
	const hasSidebarContent = useState('hasSidebarContent', () => false);

	function toggleSidebar() {
		sidebarOpen.value = !sidebarOpen.value;
	}

	function closeSidebar() {
		// Only close sidebar if screen is smaller than lg breakpoint
		if (!isLargeScreen.value) {
			sidebarOpen.value = false;
		}
	}

	function setSidebarContent(hasContent: boolean) {
		hasSidebarContent.value = hasContent;
	}

	return {
		sidebarOpen,
		hasSidebarContent,
		toggleSidebar,
		closeSidebar,
		setSidebarContent,
	};
}

export { useSidebar };
