import { AxosEntities } from '~/types/axos-api';

export default function (entity: AxosEntities) {
	const query = gql`
		query getEntityAccess($entity: AxosEntities!) {
			entityAccess(entity: $entity) {
				rowId
				canRead
				canWrite
			}
		}
	`;

	const { data, suspense, onSuccess, onData } = useLegacyQuery(query, {
		key: ['entityAccess', entity],
		variables: {
			entity,
		},
		queryOptions: {
			// 15 minutes
			staleTime: Number.POSITIVE_INFINITY,
		},
	});

	function hasRowAccess(rowId: number) {
		const canWrite = !!data.value?.entityAccess?.find(
			(x) => x.rowId === rowId && x.canWrite
		);
		const canRead =
			!!data.value?.entityAccess?.find(
				(x) => x.rowId === rowId && x.canRead
			) || false;

		return {
			canRead,
			canWrite,
		};
	}

	return {
		hasRowAccess,
		suspense,
	};
}
