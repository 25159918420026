<template>
	<header
		class="fixed inset-x-0 top-0 z-50 transform-gpu overflow-y-visible bg-primary transition-[transform,padding] duration-200 ease-out will-change-transform"
		:class="{
			'bg-slate-800': profile?.isImpersonated,
			'translate-x-80':
				sidebarOpen && hasSidebarContent && !showSidebarLabels,
			'translate-x-96':
				sidebarOpen && hasSidebarContent && showSidebarLabels,
			'translate-x-24':
				sidebarOpen && !hasSidebarContent && showSidebarLabels,
			'translate-x-16':
				sidebarOpen && !hasSidebarContent && !showSidebarLabels,
			'lg:translate-x-0': true,
		}"
	>
		<div class="mx-auto h-14">
			<div class="relative flex h-full">
				<!-- Sidebar toggle-->
				<div
					class="fixed top-0 z-[60] flex h-[var(--header-height)] w-14 items-center justify-center bg-primary-600 lg:hidden"
				>
					<button
						type="button"
						class="block p-4 text-slate-200 lg:hidden"
						@click="toggleSidebar"
					>
						<span class="sr-only">Open sidebar</span>

						<Icon
							:name="ICON_BARS"
							class="h-7 w-7"
							aria-hidden="true"
						/>
					</button>
				</div>

				<!-- Logo -->
				<NuxtLink
					to="/"
					class="flex w-16 flex-shrink-0 items-center justify-center transition-colors hover:bg-[rgba(255,255,255,.05)] lg:mx-auto lg:ml-0"
					:class="{
						'ml-14 lg:w-24': showSidebarLabels,
						'ml-14': !showSidebarLabels,
					}"
				>
					<img
						src="/axos-logo-header.svg"
						alt="Axos logo"
						class="h-7"
					/>
				</NuxtLink>

				<span
					class="pointer-events-none absolute bottom-5 rounded-full bg-green-400 px-1 text-[8px] font-semibold uppercase leading-4 text-white shadow"
					:class="{
						'left-[80px] lg:left-12': showSidebarLabels,
						'left-[80px] lg:left-8': !showSidebarLabels,
					}"
					>Beta</span
				>

				<!-- Search -->
				<AppSearch />

				<!-- Actions -->
				<div class="ml-auto flex items-center justify-end gap-x-3 px-4">
					<!-- Notification inbox -->
					<NotificationInbox />

					<!-- Organisation dropdown -->
					<OrganisationSwitch />

					<!-- Profile dropdown -->
					<Menu as="div" class="relative flex-shrink-0">
						<div>
							<MenuButton
								class="group flex overflow-hidden rounded-full bg-slate-800"
							>
								<span class="sr-only">Open user menu</span>

								<Image
									v-if="profile?.profilePicture"
									:src="profile.profilePicture"
									:height="72"
									:width="72"
									class="aspect-square h-9 w-9 rounded-full object-cover transition-opacity group-hover:opacity-90"
									:alt="`${profile?.firstName} ${profile?.lastName} `"
								/>
								<div
									v-else
									class="flex h-9 w-9 items-center justify-center rounded-full bg-white font-bold text-primary"
								>
									{{ profile?.initials }}
								</div>
							</MenuButton>
						</div>
						<transition
							enter-active-class="transition ease-out duration-100"
							enter-from-class="transform opacity-0 scale-95"
							enter-to-class="transform opacity-100 scale-100"
							leave-active-class="transition ease-in duration-75"
							leave-from-class="transform opacity-100 scale-100"
							leave-to-class="transform opacity-0 scale-95"
						>
							<MenuItems
								class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
							>
								<MenuItem v-slot="{ active, close }">
									<NuxtLink
										:to="`/employees/${profile?.id}`"
										:class="[
											active ? 'bg-slate-100' : '',
											'block w-full px-4 py-2 text-left text-sm font-semibold text-slate-700',
										]"
										@click="close"
									>
										{{ profile?.firstName }}
										{{ profile?.lastName }}
									</NuxtLink>
								</MenuItem>

								<MenuItem
									v-if="profile?.isImpersonated"
									v-slot="{ active }"
								>
									<button
										to="/auth/client"
										:class="[
											active ? 'bg-slate-100' : '',
											'block w-full border-t border-slate-200 px-4 py-2 text-left text-sm text-slate-700',
										]"
										@click="stopImpersonating"
									>
										Bytt tilbake til
										<p class="font-semibold">
											{{
												profile.impersonatedBy
													?.firstName
											}}
											{{
												profile.impersonatedBy?.lastName
											}}
										</p>
									</button>
								</MenuItem>
								<MenuItem
									v-if="
										currentClient &&
										clients &&
										clients.length > 1
									"
									v-slot="{ active }"
								>
									<NuxtLink
										to="/auth/client"
										:class="[
											active ? 'bg-slate-100' : '',
											'block w-full border-t border-slate-200 px-4 py-2 text-left text-xs font-medium text-slate-500',
										]"
									>
										{{ currentClient.name }} (bytt)
									</NuxtLink>
								</MenuItem>

								<MenuItem v-slot="{ active }">
									<button
										:class="[
											active ? 'bg-slate-100' : '',
											'block w-full border-t border-slate-200 px-4 py-2 text-left text-sm text-slate-700',
										]"
										@click="logout"
									>
										Logg ut
									</button>
								</MenuItem>
								<MenuItem v-if="isAdmin" v-slot="{ active }">
									<button
										:class="[
											active ? 'bg-slate-100' : '',
											'block w-full px-4 py-2 text-left  text-sm text-red-600',
										]"
										@click="clearAccess"
									>
										Tøm tilgang cache
									</button>
								</MenuItem>
							</MenuItems>
						</transition>
					</Menu>
				</div>
			</div>
		</div>
	</header>
</template>

<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';

const { isAdmin } = useAccessControl();

const { profile } = useProfile();
const { logout } = useAuthAxos();

const { stopImpersonating } = useImpersonate();

const { currentClient, clients } = useAuthClient();

const { sidebarOpen, hasSidebarContent, toggleSidebar } = useSidebar();
const showSidebarLabels = useLocalStorage('showSidebarLabels', true);

const { clearAccessCache } = useClearAccessCache();
async function clearAccess() {
	await clearAccessCache();
	$success('Tilgang cache tømt');
}
</script>
