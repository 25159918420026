import { AxosCapabilities } from '~/types/axos-api';

export default function () {
	const query = gql`
		query GetCapabilitiesForMe {
			capabilitiesForMe
		}
	`;

	const { data, ...args } = useLegacyQuery(query, {
		key: ['capabilitiesForMe'],
		queryOptions: {
			staleTime: Number.POSITIVE_INFINITY,
			cacheTime: 1000 * 60 * 10, // 10 minutes
		},
	});

	function hasCapability(capability: AxosCapabilities): boolean {
		return data.value?.capabilitiesForMe?.includes(capability) ?? false;
	}

	return {
		capabilities: computed(() => data.value?.capabilitiesForMe),
		hasCapability,
		...args,
	};
}
