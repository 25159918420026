import { useMutation } from '@tanstack/vue-query';

interface UploadImageResult {
	uniqueGuid: string;
	fileUrl: string;
}

export default function () {
	const { restDefaults } = useRequestDefaults();

	const { mutateAsync, error, ...args } = useMutation({
		mutationFn: async (file: File) => {
			const formData = new FormData();
			formData.append('file', file);

			return await $fetch<UploadImageResult>('/api/upload/uploadImage', {
				body: formData,
				method: 'POST',
				...restDefaults,
			});
		},
	});

	return {
		...args,
		upload: mutateAsync,
	};
}
