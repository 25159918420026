import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/vue-query';
import { createEventHook, MaybeComputedRef, MaybeRef } from '@vueuse/core';
import { RequestDocument, Variables } from 'graphql-request';
import { AxosError, Query } from '~/types/axos-api';
import { Pagination } from '~/types/pagination';

interface QueryWrapperOptions {
	key: QueryKey;
	variables?: MaybeComputedRef<Variables> | MaybeRef<Variables>;
	queryOptions?: UseQueryOptions<Query>;
	pagination?: Pagination;
}

/**
 * @deprecated Use `useQuery` directly from @tanstack/vue-query instead
 * @param query
 * @param options
 * @returns
 */
export default function useQueryWrapper<TData = Query, TVariables = unknown>(
	query: MaybeRef<
		RequestDocument | TypedDocumentNode<Query, TVariables> | null
	>,
	options: QueryWrapperOptions
) {
	const app = useNuxtApp();

	const onSuccess = createEventHook<TData>();
	const onData = createEventHook<TData>();

	const variables = computed(() => {
		let variables = options?.variables
			? unref(options.variables)
			: undefined;

		if (options?.pagination) {
			variables = {
				...variables,
				take: options.pagination.take.value,
				skip: options.pagination.skip.value,
			};
		}
		return variables;
	});

	const { data, error, ...args } = useQuery<TData, AxosError, TData>({
		queryKey: options?.pagination
			? [...options.key, options.pagination.queryKey]
			: options.key,
		queryFn: async () => {
			try {
				const res = await app.$graphql.request<any, any>(
					unref(query) as any,
					variables.value
				);

				return res;
			} catch (error: any) {
				const errorData = error?.data?.errors?.[0];
				if (errorData) {
					throw errorData;
				} else {
					throw error;
				}
			}
		},

		keepPreviousData: !!options?.pagination,

		// TODO: Fix correct typing
		...(options.queryOptions as any),
		onSuccess: (data) => {
			onSuccess.trigger(data);
		},
	});

	watch(
		() => data.value,
		(data) => onData.trigger(data)
	);

	return {
		data,
		onSuccess: onSuccess.on,
		onData: onData.on,
		error,
		...args,
	};
}
