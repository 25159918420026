import { NotificationItem } from '~/codegen/axos-client';

export default function () {
	const app = useNuxtApp();
	const connection = app.$signalR;

	const { invalidate } = useInvalidateQuery();

	const onEntityEvent = createEventHook<NotificationItem>();

	connection.on('ReceiveNotification', (notification: NotificationItem) => {
		onEntityEvent.trigger(notification);
		invalidate([
			'entityEvents',
			notification.entity,
			notification.instanceId,
		]);

		invalidate(['notifications']);
	});

	return {
		onNotificationReceived: onEntityEvent.on,
	};
}
