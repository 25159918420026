import { QueryKey, useQueryClient } from '@tanstack/vue-query';

interface Options {
	exact?: boolean;
}

export default function useInvalidateQuery(
	queryKey?: QueryKey,
	options?: Options
) {
	const queryClient = useQueryClient();

	function invalidate(
		queryKeyOverride?: QueryKey,
		optionsOverride?: Options
	) {
		if (!queryKey && !queryKeyOverride) {
			throw new Error(
				'useInvalidateQuery: No query key provided. Provide a query key or use the invalidate function returned by the hook.'
			);
		}

		return queryClient.invalidateQueries({
			queryKey: queryKey ?? queryKeyOverride,
			exact: options?.exact ?? optionsOverride?.exact ?? false,
			refetchType: 'all',
		});
	}

	return {
		invalidate,
	};
}
