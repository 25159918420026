import { useQuery } from '@tanstack/vue-query';

export default function useDropdownIdeaImportanceType() {
	const query = gql`
		query getIdeaImportanceTypeDropdown {
			ideaImportanceTypeDropdown {
				value
				label
			}
		}
	`;

	const { data, suspense, ...args } = useQuery({
		queryKey: ['ideaImportanceTypeDropdown'],
		queryFn: useGQLRequest(query),
		select: (data) => data.ideaImportanceTypeDropdown,
		staleTime: 60 * 60, // Dropdown staleTime
	});

	const { mappedData, getOption, getOptions, getOptionLabel } =
		useDropdownUtils(data, suspense);

	return {
		ideaImportanceTypes: mappedData,
		getIdeaImportanceTypeOption: getOption,
		getIdeaImportanceTypeOptions: getOptions,
		getIdeaImportanceTypeOptionLabel: getOptionLabel,
		...args,
	};
}
