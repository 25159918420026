<template>
	<Teleport to="body">
		<div
			v-if="toasts.length"
			aria-live="assertive"
			class="pointer-events-none fixed inset-0 bottom-2 z-[9999] flex content-end items-end px-4 pt-5 sm:bottom-0 sm:items-start"
		>
			<div class="flex w-full flex-col items-end space-y-4">
				<TransitionGroup
					enter-active-class="transform ease-out duration-300 transition"
					enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
					enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
					leave-active-class="transition ease-in duration-100"
					leave-from-class="opacity-100"
					leave-to-class="opacity-0"
				>
					<template v-for="toast in toasts" :key="toast.id">
						<div
							class="pointer-events-auto w-full overflow-hidden rounded-lg shadow-lg sm:max-w-sm"
							:class="{
								'bg-green-500':
									toast.type === ToastType.SUCCESS,
								'bg-red-400': toast.type === ToastType.ERROR,
								'bg-blue-400': toast.type === ToastType.INFO,
							}"
						>
							<div class="flex items-start p-3">
								<div class="-mt-0.5 flex-shrink-0">
									<Icon
										v-if="toast.type === ToastType.SUCCESS"
										:name="ICON_CHECK_CIRCLE"
										class="h-6 w-6 text-white"
										aria-hidden="true"
									/>

									<Icon
										v-if="toast.type === ToastType.ERROR"
										:name="ICON_X_CIRCLE"
										class="h-6 w-6 text-white"
										aria-hidden="true"
									/>

									<Icon
										v-if="toast.type === ToastType.INFO"
										:name="ICON_INFO"
										class="h-6 w-6 text-white"
										aria-hidden="true"
									/>
								</div>

								<p
									class="text ml-2 mt-0.5 flex-1 text-sm font-semibold text-white"
								>
									{{ $s(toast.message) }}
								</p>

								<div class="ml-4 flex flex-shrink-0">
									<button
										type="button"
										class="inline-flex rounded p-1 text-white"
										:class="{
											'bg-green-600':
												toast.type ===
												ToastType.SUCCESS,
											'bg-red-600':
												toast.type === ToastType.ERROR,
											'bg-blue-600':
												toast.type === ToastType.INFO,
										}"
										@click="remove(toast.id)"
									>
										<span class="sr-only">{{
											$s('Core.Label.Close')
										}}</span>

										<Icon
											:name="ICON_X"
											class="h-4 w-4"
											aria-hidden="true"
										/>
									</button>
								</div>
							</div>
						</div>
					</template>
				</TransitionGroup>
			</div>
		</div>
	</Teleport>
</template>
<script setup lang="ts">
import { ToastType } from '~/composables/useToasts';

const { toasts, remove } = useToasts();
</script>
