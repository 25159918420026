<template>
	<div v-if="file || context?._value" class="mt-2">
		<div
			class="relative max-w-48 bg-repeat"
			style="
				background-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns=\'http://www.w3.org/2000/svg\' width=\'16\' height=\'16\' viewBox=\'0 0 8 8\'><rect fill=\'%23ffffff\' width=\'8\' height=\'8\'/><rect fill=\'%23eeeeee\' width=\'4\' height=\'4\'/><rect fill=\'%23eeeeee\' x=\'4\' y=\'4\' width=\'4\' height=\'4\'/></svg>');
			"
		>
			<div
				v-if="isPending"
				class="absolute bottom-0 left-0 right-0 top-0 z-50 flex flex-col items-center justify-center text-xl"
			>
				<LoadingSpinner />
				<div class="text-sm font-semibold text-primary">
					{{ $s('Core.Label.UploadingImage') }}
				</div>
			</div>

			<Image
				v-if="file"
				:src="getImageFromFile(file)"
				class="h-full w-full rounded-lg object-contain transition-all"
				width="500"
				:class="{
					'opacity-20': isPending,
					'opacity-100': !isPending,
				}"
			/>
			<Image
				v-else-if="context?._value"
				:src="context._value"
				class="h-full w-full rounded-lg object-contain transition-all"
				width="500"
				:class="{
					'opacity-20': isPending,
					'opacity-100': !isPending,
				}"
			/>
		</div>

		<div v-if="!context?.disabled" class="mt-2">
			<Button theme="neutral" @click="triggerFileSelect" size="xsmall">
				{{ $s('Core.Button.ChangeImage') }}
			</Button>
		</div>
		<input
			:id="context?.id"
			type="file"
			class="hidden"
			accept="image/*"
			@change="onFileSelected"
		/>
	</div>

	<div
		v-else-if="!context?.disabled"
		id="image-preview"
		class="mb-4 mt-2 max-w-96 items-center rounded-lg border-2 border-dashed border-slate-300 text-center transition-colors hover:border-primary-600"
	>
		<input
			:id="context?.id"
			type="file"
			class="hidden"
			accept="image/*"
			@change="onFileSelected"
		/>

		<label :for="context?.id" class="block cursor-pointer p-6 text-center">
			<Icon
				:name="ICON_UPLOAD"
				class="mx-auto h-9 w-9 text-primary-600"
				aria-hidden="true"
			/>
			<h3 class="text-md mb-2 font-semibold text-primary-600">
				{{ $s('Core.Button.UploadImage') }}
			</h3>
			<p class="text-xs font-normal leading-4 text-slate-400 md:px-6">
				{{ $s('Core.Label.ImageHasToBeSmallerThan') }}
				<b class="text-slate-600">5 MB</b>
			</p>
			<p class="text-xs font-normal leading-4 text-slate-400 md:px-6">
				{{ $s('Core.Label.ImageHasToBeOfType') }}
				<b class="text-slate-600"> JPG, PNG </b>
			</p>
			<span id="filename" class="z-50 bg-slate-200 text-slate-500"></span>
		</label>
	</div>
</template>
<script setup lang="ts">
const props = defineProps({
	context: Object,
});

const file = ref<File | null>(null);

function handleInput(value: string) {
	props.context?.node.input(value);
}

function triggerFileSelect() {
	const input = document.getElementById(
		props.context!.id
	) as HTMLInputElement;
	input.click();
}

const { upload, isPending } = useImageUpload();
async function onFileSelected(event: Event) {
	const target = event.target as HTMLInputElement;
	const fileList = target.files;

	const selectedFile = fileList?.[0];
	if (!selectedFile) {
		return;
	}

	file.value = selectedFile;

	const uploadedFile = await upload(file.value);
	handleInput(uploadedFile.fileUrl);
}

function getImageFromFile(file: File) {
	return URL.createObjectURL(file);
}
</script>
