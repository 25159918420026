<template>
	<main>
		<TransitionRoot as="template" :show="sidebarOpen">
			<TransitionChild
				as="template"
				enter="transition ease-out duration-150 transform"
				enter-from="-translate-x-full"
				enter-to="translate-x-0"
				leave="transition ease-out duration-150 transform"
				leave-from="translate-x-0"
				leave-to="-translate-x-full"
			>
				<aside
					aria-label="Sidebar"
					class="fixed inset-y-0 z-50 flex shrink-0 border-r lg:z-40 lg:mt-[var(--header-height-lg)] lg:border-0"
					:class="{
						'w-96': showSidebarLabels && $slots.sidebar,
						'w-80': $slots.sidebar,
						'w-16': !$slots.sidebar,
					}"
				>
					<!-- Main sidebar with icons -->
					<nav
						class="z-20 shrink-0 bg-slate-700"
						:class="{
							'w-24 p-2': showSidebarLabels,
							'w-16 py-1.5': !showSidebarLabels,
						}"
					>
						<ul
							role="list"
							class="flex h-full flex-col gap-y-1"
							:class="{
								'items-left': showSidebarLabels,
								'items-center': !showSidebarLabels,
							}"
						>
							<!-- Close sidebar button-->
							<li>
								<button
									type="button"
									class="flex w-full items-center justify-center px-4 py-3 text-slate-200 lg:hidden"
									@click="toggleSidebar"
								>
									<span class="sr-only">Close sidebar</span>

									<Icon
										:name="ICON_X"
										class="h-7 w-7"
										aria-hidden="true"
									/>
								</button>
							</li>

							<li v-if="hasModule(AxosModules.Crm)">
								<NuxtLink
									to="/accounts"
									:class="{
										'!border-slate-800/50 bg-slate-800 bg-opacity-60 text-white':
											isActive('/account') ||
											isActive('/contacts') ||
											isActive('/crm'),
									}"
									class="font-regular group relative flex flex-col items-center rounded-md border border-transparent p-3 pb-2.5 text-xxs leading-6 text-slate-400 transition-all hover:text-white"
								>
									<Icon
										:name="ICON_CRM"
										class="h-6 w-6 shrink-0"
										aria-hidden="true"
									/>

									<span v-if="showSidebarLabels">
										{{ $p('Core.Navigation.CRM') }}
									</span>

									<Tooltip v-else>
										{{ $p('Core.Navigation.CRM') }}
									</Tooltip>
								</NuxtLink>
							</li>
							<li
								v-if="
									hasModule([
										AxosModules.Hr,
										AxosModules.Employee,
									])
								"
							>
								<NuxtLink
									to="/employees"
									:class="{
										' !border-slate-800/50 bg-slate-800 bg-opacity-60 text-white':
											isActive('/employees') ||
											isActive('/allergies') ||
											isActive('/hr'),
									}"
									class="font-regular group relative flex flex-col items-center rounded-md border border-transparent p-3 pb-2.5 text-xxs leading-6 text-slate-400 transition-all hover:text-white"
								>
									<Icon
										:name="ICON_HR"
										class="h-6 w-6 shrink-0"
										aria-hidden="true"
									/>

									<span v-if="showSidebarLabels">
										{{ $p('Core.Navigation.HR') }}
									</span>

									<Tooltip v-else>
										{{ $p('Core.Navigation.HR') }}
									</Tooltip>
								</NuxtLink>
							</li>
							<li v-if="hasModule(AxosModules.Sales)">
								<NuxtLink
									to="/leads"
									:class="{
										'!border-slate-800/50 bg-slate-800 bg-opacity-60 text-white':
											isActive('/sales') ||
											isActive('/leads'),
									}"
									class="font-regular group relative flex flex-col items-center rounded-md border border-transparent p-3 pb-2.5 text-xxs leading-6 text-slate-400 transition-all hover:text-white"
								>
									<Icon
										:name="ICON_SALES"
										class="h-6 w-6 shrink-0"
										aria-hidden="true"
									/>

									<span v-if="showSidebarLabels">
										{{ $p('Core.Navigation.Sales') }}
									</span>

									<Tooltip v-else>
										{{ $p('Core.Navigation.Sales') }}
									</Tooltip>
								</NuxtLink>
							</li>
							<li v-if="hasModule(AxosModules.Finance)">
								<NuxtLink
									to="/products"
									:class="{
										'bg-slate-800 bg-opacity-60 text-white':
											isActive('/finance') ||
											isActive('/products'),
									}"
									class="font-regular group relative flex flex-col items-center rounded-md p-3 text-xxs leading-6 text-slate-400 transition-all hover:text-white"
								>
									<Icon
										:name="ICON_FINANCE"
										class="h-6 w-6 shrink-0"
										aria-hidden="true"
									/>

									<span v-if="showSidebarLabels">
										{{ $p('Core.Navigation.Finance') }}
									</span>

									<Tooltip v-else>
										{{ $p('Core.Navigation.Finance') }}
									</Tooltip>
								</NuxtLink>
							</li>
							<li class="flex-grow"></li>
							<li v-if="hasModule(AxosModules.Organisation)">
								<NuxtLink
									to="/organisations"
									:class="{
										'!border-slate-800/50 bg-slate-800 bg-opacity-60 text-white':
											isActive('/organisations'),
									}"
									class="font-regular group relative flex flex-col items-center rounded-md border border-transparent p-3 pb-2.5 text-xxs leading-6 text-slate-400 transition-all hover:text-white"
									@click="closeSidebar"
								>
									<Icon
										:name="ICON_ORGANISATION"
										class="h-6 w-6 shrink-0"
										aria-hidden="true"
									/>

									<span v-if="showSidebarLabels">
										{{ $p('Organisation.Entity.Name') }}
									</span>

									<Tooltip v-else>
										{{ $p('Organisation.Entity.Name') }}
									</Tooltip>
								</NuxtLink>
							</li>
							<!-- <li v-if="isAdmin">
								<NuxtLink
									to="/admin"
									:class="{
										'border-slate-800/50 bg-slate-800 bg-opacity-60 text-white':
											isActive('/admin'),
									}"
									class="border border-transparent font-regular group relative flex flex-col items-center rounded-md p-3 pb-2.5 text-xxs leading-6 text-slate-400 transition-all hover:text-white"
								>
									<Icon
										:name="ICON_ADMIN"
										class="h-6 w-6 shrink-0"
										aria-hidden="true"
									/>

									<span v-if="showSidebarLabels">
										{{ $s('Core.Navigation.Admin') }}
									</span>

									<Tooltip v-else>
										{{ $s('Core.Navigation.Admin') }}
									</Tooltip>
								</NuxtLink>
							</li> -->

							<Idea :show-label="showSidebarLabels" />

							<li class="mt-2 border-t border-slate-600 pt-4">
								<button
									@click="toggleSidebarLabels"
									class="font-regular group relative flex w-full flex-col items-center rounded-md border border-transparent p-3 pb-4 pt-1.5 leading-6 text-slate-400 transition-all hover:text-white"
									:class="{
										'mt-0.5': showSidebarLabels,
										'mt-1': !showSidebarLabels,
									}"
								>
									<Icon
										:name="
											showSidebarLabels
												? ICON_CHEVRON_LEFT_DOUBLE
												: ICON_CHEVRON_RIGHT_DOUBLE
										"
										class="h-6 w-6 shrink-0"
										aria-hidden="true"
									/>

									<Tooltip>
										<template v-if="showSidebarLabels">
											{{
												$s(
													'Core.Navigation.CollapseSidebar'
												)
											}}
										</template>
										<template v-else>
											{{
												$s(
													'Core.Navigation.ExpandSidebar'
												)
											}}
										</template>
									</Tooltip>
								</button>
							</li>
						</ul>
					</nav>

					<!-- Sidebar menu -->
					<div
						v-if="$slots.sidebar"
						class="z-10 flex w-72 flex-col overflow-y-auto bg-white p-4"
					>
						<slot
							class="mx-auto mt-10 flex px-4"
							name="sidebar"
						></slot>
					</div>
				</aside>
			</TransitionChild>
		</TransitionRoot>

		<!-- Main content -->
		<div
			class="relative flex min-h-[calc(100vh-var(--header-height))] flex-col transition-[transform,padding] duration-200 ease-out will-change-transform lg:min-h-[calc(100vh-var(--header-height-lg))] lg:translate-x-0"
			:class="{
				'lg:pl-80': $slots.sidebar && !showSidebarLabels,
				'lg:pl-96': $slots.sidebar && showSidebarLabels,
				'lg:pl-24': !$slots.sidebar && showSidebarLabels,
				'lg:pl-16': !$slots.sidebar && !showSidebarLabels,
				'translate-x-80':
					sidebarOpen && $slots.sidebar && !showSidebarLabels,
				'translate-x-96':
					sidebarOpen && $slots.sidebar && showSidebarLabels,
				'translate-x-24':
					sidebarOpen && !$slots.sidebar && showSidebarLabels,
				'translate-x-16':
					sidebarOpen && !$slots.sidebar && !showSidebarLabels,
				'before:absolute before:inset-0 before:z-50 before:bg-slate-900/50 before:transition-opacity before:duration-200':
					sidebarOpen && !isLg,
				'before:pointer-events-none before:opacity-0':
					!sidebarOpen && !isLg,
				'before:opacity-100': sidebarOpen && !isLg,
			}"
			@click="sidebarOpen && !isLg ? toggleSidebar() : null"
		>
			<!-- Header -->
			<header
				v-if="$slots.header"
				class="relative z-40 flex min-h-20 items-center bg-white lg:min-h-28"
			>
				<PageContentWrapper :narrow="narrow">
					<slot name="header"></slot>
				</PageContentWrapper>
			</header>

			<!-- Content -->
			<div
				class="flex-1 border-t lg:rounded-tl-xl lg:border-l lg:bg-slate-75"
			>
				<PageContentWrapper :narrow="narrow" is-main>
					<slot> </slot>
				</PageContentWrapper>
			</div>
		</div>
	</main>
</template>

<script setup lang="ts">
import { TransitionChild, TransitionRoot } from '@headlessui/vue';

const props = defineProps<{
	narrow?: boolean;
}>();

const { isAdmin } = useAccessControl();
const { hasModule } = useModules();

const route = useRoute();
const isActive = (path: string) => {
	return route.path.startsWith(path);
};
const { sidebarOpen, toggleSidebar, closeSidebar, setSidebarContent } =
	useSidebar();
const showSidebarLabels = useLocalStorage('showSidebarLabels', true);

const toggleSidebarLabels = () => {
	showSidebarLabels.value = !showSidebarLabels.value;
};

const breakpoints = useBreakpoints({
	lg: 1024, // matches Tailwind's lg breakpoint
});
const isLg = breakpoints.greater('lg');

watch(isLg, (value) => {
	sidebarOpen.value = value;
});

const slots = useSlots();

// Watch for changes in the sidebar slot
watch(
	() => !!slots.sidebar,
	(hasSidebar) => {
		setSidebarContent(hasSidebar);
	},
	{ immediate: true }
);
</script>
