import {
	DropdownItemInput,
	DropdownItemAttrs,
	DropdownItem,
} from '~/types/dropdown';

export default function <T extends DropdownItemInput>(
	data: Ref<undefined | T[]>,
	suspense: () => Promise<any>
) {
	const mappedData = computed(() => getLocalizedSortedData(data.value));

	function formatOptionAttrs(item: T) {
		const attrs = {
			...item,
			deleted: item.isDeleted,
			disabled: item.isDeleted,
			sortIndex: item.sortIndex,
		};

		delete attrs.value;
		delete attrs.label;

		return attrs as DropdownItemAttrs<T>;
	}

	function getLocalizedSortedData(data?: T[]) {
		const mappedData =
			(data?.map((item: T) => ({
				...item,
				label: $s(item.label),
				attrs: formatOptionAttrs(item),
			})) as DropdownItem<T>[]) ?? [];

		const hasPositiveSortIndex = mappedData?.some(
			(item: DropdownItem<T>) =>
				item.attrs.sortIndex && item.attrs.sortIndex > 0
		);

		if (hasPositiveSortIndex) {
			return mappedData?.sort(
				(a: DropdownItem<T>, b: DropdownItem<T>) => {
					const indexA = a.attrs.sortIndex || 0; // Treat null or undefined as 0
					const indexB = b.attrs.sortIndex || 0;
					return indexA - indexB;
				}
			);
		} else {
			return mappedData?.sort((a: DropdownItem<T>, b: DropdownItem<T>) =>
				a.label.localeCompare(b.label)
			);
		}
	}

	async function getOptions(options?: { search?: string }) {
		await suspense();

		if (options?.search) {
			return mappedData.value?.filter((item) =>
				item.label.toLowerCase().includes(options.search!.toLowerCase())
			);
		}

		return mappedData.value;
	}

	async function getOption(
		value: number | string | null,
		cachedItem?: DropdownItem<T>
	) {
		if (cachedItem) {
			return cachedItem;
		}

		await suspense();

		const item = mappedData.value?.find(
			(item) => item.value === value || item.__original === value
		);

		return item ?? null;
	}

	function getOptionLabel(value: number | string | null): string | null {
		if (!value) {
			return null;
		}

		const item = mappedData.value?.find(
			(item) => item.value === value || item.__original === value
		);

		return item?.label ?? null;
	}

	return {
		getLocalizedSortedData,
		getOptions,
		getOption,
		getOptionLabel,
		mappedData,
	};
}
