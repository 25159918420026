export default defineNuxtRouteMiddleware(async (to, from) => {
	const { IS_DEV, IS_TEST } = useEnvironment();

	if (IS_TEST) {
		return;
	}

	const ignoredPaths = [
		'/setup/client',
		'/no-clients-available',
		'/auth/login',
		'/server-error',
	];

	if (IS_DEV && ignoredPaths.includes(to.path)) {
		return;
	}

	const { login } = useAuthAxos();

	const { isAuthenticated, error, suspense } = useIsAuthenticated();
	await suspense();

	const config = useRuntimeConfig();
	if (!isAuthenticated.value) {
		const { data, error } = await useFetch('/', {
			baseURL: config.public.BFF_URL,
		});

		const isServerDown =
			error.value?.status === 503 ||
			error.value?.message === 'Network Error' ||
			error.value?.message?.includes('Failed to fetch');

		if (isServerDown) {
			return navigateTo('/server-error?code=ECONNREFUSED');
		}

		return login();
	}
});
