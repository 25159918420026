import { GraphQLClient } from 'graphql-request';

export default defineNuxtPlugin((nuxtApp) => {
	const config = useRuntimeConfig();

	function autoPickErrors(response: any) {
		if (!response) {
			return response;
		}
		const keys = Object.keys(response);

		// If top level is "errors", return it
		if (keys.includes('errors')) {
			return response;
		}

		return keys.length === 1 ? response[keys[0] as string] : response;
	}

	function getErrorFromResponse(response: any) {
		if (response?.data) {
			const autoPickedData = autoPickErrors(response.data);
			if (autoPickedData?.errors) {
				return autoPickedData.errors?.[0];
			}
		}
	}

	/**
	 * API does not return a 4xx or 5xx status code when an NotFoundError
	 * or EntityValidationError occurs, so we need to check for those
	 * errors in the response body and throw them manually.
	 */
	function errorMiddleware(response: any) {
		// Check if response is of type error
		const responseErrors = response?.response?.errors;
		if (response instanceof Error && responseErrors) {
			throw createError({
				message: 'Error from API',
				data: { errors: responseErrors },
			});
		}

		const error = getErrorFromResponse(response);
		if (error) {
			throw createError({
				message:
					error?.resourceId ?? error?.message ?? 'Error from API',
				data: error,
			});
		}
	}

	// TODO: Handle client resolving
	const client = new GraphQLClient(config.public.BFF_URL + '/api/graphql', {
		headers: () => {
			const storedImpersonatingId =
				localStorage.getItem('impersonatingId');
			return {
				'GraphQL-preflight': '1',
				'Impersonate-Id': storedImpersonatingId || undefined,
			} as Record<string, string>;
		},
		credentials: 'include',
		responseMiddleware: errorMiddleware,
	});

	return {
		provide: {
			graphql: client,
		},
	};
});
