import { useModal, useModalSlot, VueFinalModal } from 'vue-final-modal';

export default function <T = any, TPayload = any>(
	options: Parameters<typeof useModalSlot>['0']
) {
	function openModal(
		variables?: object
	): Promise<{ value: T; confirmed: T; payload?: TPayload }> {
		return new Promise((resolve, reject) => {
			const modalInstance = useModal({
				component: VueFinalModal,
				slots: {
					default: useModalSlot({
						component: options.component,
						attrs: {
							...variables,
							resolve: resolveResult,
						},
					}),
				},
			});

			function resolveResult(value: T, payload: TPayload) {
				modalInstance.close();
				resolve({ value, payload, confirmed: value });
			}

			modalInstance.open();
		});
	}

	return { openModal };
}
