export default function () {
	const config = useRuntimeConfig();
	const isDev =
		config.public.ENV === 'development' || config.public.ENV === 'local';

	const isTest = config.public.ENV === 'test';

	return {
		IS_DEV: isDev,
		IS_TEST: isTest,
	};
}
