import { AxosEntities } from '~/types/axos-api';

export default async function (entity: AxosEntities, rowId: number) {
	const { profile } = useProfile();

	const { hasRowAccess, suspense } = useEntityAccess(entity);
	await suspense();

	const rowAccess = hasRowAccess(rowId);

	const isLoggedInEmployee =
		entity === AxosEntities.Employee && profile.value?.id === rowId;

	return {
		canRead: profile.value?.isAdmin || rowAccess.canRead,
		canWrite: profile.value?.isAdmin || rowAccess.canWrite,
		isLoggedInEmployee,
	};
}
