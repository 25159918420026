<template>
	<!-- Search bar -->
	<div
		class="mx-auto flex min-w-0 flex-1 justify-center pl-4 sm:ml-16 sm:max-w-none sm:pl-0"
		:class="{
			'fixed  right-28 top-0 z-10 !max-w-none sm:right-36':
				wideOnSmallScreens && isSmallScreen,
			'left-26 sm:left-28': showSidebarLabels,
			'left-12 sm:left-20': !showSidebarLabels,
		}"
	>
		<div class="mx-auto flex flex-1 items-center">
			<div class="relative w-full">
				<TransitionRoot
					:show="open"
					as="template"
					@after-leave="query = ''"
					appear
				>
					<div
						class="absolute -top-7 left-1/2 z-10 mt-2 w-full max-w-xl -translate-x-1/2 sm:w-[80%] lg:w-2/3 lg:max-w-3xl xl:ml-7 xl:w-3/5"
						:class="{
							'!top-0 !w-full !max-w-none':
								wideOnSmallScreens && isSmallScreen,
						}"
					>
						<TransitionChild
							as="template"
							enter="ease-out duration-300"
							enter-from="opacity-0 scale-95"
							enter-to="opacity-100 scale-100"
							leave="ease-in duration-200"
							leave-from="opacity-100 scale-100"
							leave-to="opacity-0 scale-95"
							ref="menuRef"
						>
							<div
								class="w-full transform rounded-xl transition-all"
							>
								<Combobox
									v-model="selected"
									ref="menuRef"
									nullable
								>
									<div class="relative">
										<Icon
											:name="ICON_SEARCH"
											class="pointer-events-none absolute left-4 top-2.5 h-5 w-5 text-slate-500"
											aria-hidden="true"
										/>
										<ComboboxInput
											ref="inputRef"
											@click="
												showMenu = true;
												$nextTick(() => {
													inputRef.$el?.focus();
												});
											"
											class="h-10 w-full rounded-xl border-0 bg-transparent bg-white pl-11 pr-4 text-slate-700 placeholder:text-slate-500 focus-within:ring-2 focus-within:ring-primary-400 sm:text-sm"
											placeholder="Søk …"
											:display-value="() => query"
											@change="
												query = $event.target.value;
												showSearch = true;
											"
										/>

										<button
											v-if="query !== ''"
											@click="
												showSearch = false;
												showMenu = false;
												query = '';
											"
											class="absolute right-2 top-1.5 appearance-none p-1"
										>
											<Icon
												:name="ICON_X"
												class="h-5 w-5 text-slate-500"
												aria-hidden="true"
											/>
										</button>
									</div>

									<div
										v-if="query !== ''"
										class="mt-px rounded-xl border bg-white shadow-xl"
									>
										<div
											class="mt-px flex gap-x-2 px-4 pt-3"
										>
											<Chip
												v-if="
													(results?.estimatedTotalHits ??
														0) > 0
												"
												:value="$s('Core.Label.All')"
												:count="
													results?.estimatedTotalHits
												"
												:active="entityFilter === null"
												@click="
													entityFilter = null;
													showSearch = true;
												"
												size="small"
											/>
											<Chip
												v-for="chip of resultChips"
												:value="chip.label"
												:count="chip.count"
												:active="
													entityFilter === chip.key
												"
												@click="
													entityFilter = chip.key;
													showSearch = true;
												"
												size="small"
											/>
										</div>
										<ComboboxOptions
											static
											class="mt-2 max-h-[calc(100svh-130px)] overflow-y-auto rounded-b-xl bg-white pb-2"
											@keydown="handleKeydown"
										>
											<div
												v-if="isSearching"
												class="flex items-center justify-center gap-x-2 pb-2 text-center text-slate-500"
											>
												<LoadingSpinnerV2 />
												{{ $s('Core.Label.Searching') }}
											</div>

											<div
												v-else-if="hits?.length === 0"
												class="pb-2 text-center text-slate-500"
											>
												{{ $s('Core.Label.NoResults') }}
											</div>

											<ComboboxOption
												v-for="record of hits"
												v-slot="{ active }"
												:key="record.rowId"
												:value="record"
												as="template"
											>
												<NuxtLink
													:to="record.url"
													class="search-hit block"
												>
													<li
														class="mx-2 flex cursor-pointer items-center gap-x-2 rounded-md border p-2"
														:class="{
															'border-primary-200/50 bg-primary-100 text-slate-900':
																active,
															'border-transparent bg-white text-slate-700':
																!active,
														}"
													>
														<!-- Avatar section -->
														<div
															v-if="
																record.imageUrl
															"
															class="shrink-0"
														>
															<ImageWithLoader
																:src="
																	record.imageUrl
																"
																:height="40"
																:width="40"
																class="aspect-square h-10 w-10 rounded-full object-cover"
																:alt="
																	record.title
																"
															/>
														</div>

														<div
															v-else
															class="flex aspect-square h-10 w-10 shrink-0 items-center justify-center rounded-full bg-slate-100 text-slate-500"
														>
															<Icon
																:name="
																	ICON_EMPLOYEES
																"
																class="h-6 w-6"
																aria-hidden="true"
															/>
														</div>

														<!-- Content section -->
														<div
															class="flex min-w-0 flex-col leading-none"
														>
															<h3
																class="truncate font-semibold"
																v-dompurify-html="
																	$s(
																		record
																			?.formatted
																			?.title ||
																			record.title
																	)
																"
															></h3>

															<p
																v-if="
																	record.description
																"
																class="truncate text-sm text-slate-600"
																v-dompurify-html="
																	$s(
																		record
																			?.formatted
																			?.description ||
																			record.description
																	)
																"
															></p>
														</div>
													</li>
												</NuxtLink>
											</ComboboxOption>
										</ComboboxOptions>
									</div>
								</Combobox>
							</div>
						</TransitionChild>
					</div>
				</TransitionRoot>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import {
	Combobox,
	ComboboxInput,
	ComboboxOption,
	ComboboxOptions,
	TransitionChild,
	TransitionRoot,
} from '@headlessui/vue';
import {
	onClickOutside,
	useMagicKeys,
	useFocus,
	useBreakpoints,
} from '@vueuse/core';

const NuxtLink = resolveComponent('NuxtLink');

interface SearchRecord {
	rowId: string;
	title: string;
	description?: string;
	imageUrl?: string;
	url: string;
	formatted?: {
		title?: string;
		description?: string;
	};
}

const inputRef = ref<any>();

const router = useRouter();

const menuRef = ref<HTMLDivElement | null>();
onClickOutside(menuRef, () => {
	query.value = '';
	showMenu.value = false;
	showSearch.value = false;
	wideOnSmallScreens.value = false;
});

const open = ref(true);
const wideOnSmallScreens = ref(false);
const breakpoints = useBreakpoints({
	lg: '1024px',
});
const isSmallScreen = breakpoints.smaller('lg');
const query = ref('');
const queryDebounced = refDebounced(query, 250);
const selected = ref<SearchRecord | null>(null);
const showSidebarLabels = useLocalStorage('showSidebarLabels', true);

const { focused } = useFocus(inputRef);
watch(focused, (focused) => {
	if (focused) {
		wideOnSmallScreens.value = true;
	} else if (!open.value) {
		wideOnSmallScreens.value = false;
	}
});

watch(selected, (val) => {
	if (val && val.url) {
		router.push(val.url);
		showMenu.value = false;
		query.value = '';
		entityFilter.value = null;
	}
});

const entityFilter = ref<string | null>(null);
const { hits, results } = useGlobalSearch(queryDebounced, {
	entityFilter,
});

const resultChips = computed(() => {
	const facetResults = results.value?.facetDistribution;
	if (!facetResults) return [];

	return facetResults
		.map((facet) => ({
			label: $s(`${facet.entityName}.Entity.Name`),
			count: facet.totalHits,
			key: facet.entity,
		}))
		.filter((facet) => facet.count > 0);
});

const keys = useMagicKeys();

// Esc
const esc = keys['Escape'];
watch(esc, (v) => {
	if (v && showMenu.value) {
		query.value = '';
		showMenu.value = false;
		showSearch.value = false;
		inputRef.value?.$el.blur();
		wideOnSmallScreens.value = false;
	}
});

const showMenu = ref(false);
const showSearch = ref(false);

// Add loading state
const isSearching = computed(
	() => results.value === undefined && query.value !== ''
);

// Add keyboard navigation improvements
function handleKeydown(e: KeyboardEvent) {
	if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
		e.preventDefault(); // Prevent scroll
	}
}

// Extract search result item to separate component
</script>

<style>
.search-hit mark {
	@apply bg-inherit;
}
</style>
