import { AxosError } from '~/types/axos-api';

export default function () {
	const { toCamelCase } = useTextUtils();

	function toMessage(error: AxosError) {
		return $s(error.resourceId, true) ?? error.message ?? 'Ukjent feil';
	}

	// TODO: Will be fixed in backend, this is a temporary fix
	function pathsToCamelCase(error: AxosError) {
		return {
			...error,
			path: error.path?.map((path) => toCamelCase(path)),
		};
	}

	return {
		toMessage,
		pathsToCamelCase,
	};
}
