<template>
	<div>
		<NuxtLink :to="`/organisations/${profile?.organisationId}`">
			<Image
				v-if="profile?.organisationSymbol"
				:src="profile.organisationSymbol"
				:height="72"
				:width="72"
				class="bg-slate-900 aspect-square h-9 w-9 rounded-full object-cover transition-opacity hover:opacity-90"
				:alt="profile?.organisationName"
			/>
			<div v-else>
				<div
					class="bg-white flex h-9 w-9 items-center justify-center rounded-full text-xl font-bold text-primary"
				>
					{{ profile?.organisationName[0] }}
				</div>
			</div>
		</NuxtLink>
	</div>

	<!--
		Switch concept, disabled for now

	<Menu as="div" class="relative flex-shrink-0">
		<div>
			<MenuButton
				class="group flex overflow-hidden rounded-full bg-slate-800"
			>
				<span class="sr-only">Open organisation menu</span>
				<Image
					v-if="profile?.organisationSymbol"
					:src="profile.organisationSymbol"
					:height="60"
					:width="60"
					class="relative aspect-square h-9 w-9 rounded-full object-cover"
				/>
				<div v-else>
					<div
						class="flex h-9 w-9 items-center justify-center rounded-full bg-white text-xl font-bold text-primary"
					>
						{{ profile?.organisationName[0] }}
					</div>
				</div>
			</MenuButton>
		</div>
		<transition
			enter-active-class="transition ease-out duration-100"
			enter-from-class="transform opacity-0 scale-95"
			enter-to-class="transform opacity-100 scale-100"
			leave-active-class="transition ease-in duration-75"
			leave-from-class="transform opacity-100 scale-100"
			leave-to-class="transform opacity-0 scale-95"
		>
			<MenuItems
				class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white p-1.5 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
			>
				<MenuItem v-slot="{ active }">
					<button
						:class="[
							active ? 'bg-slate-100' : '',
							'flex w-full items-center gap-x-2 rounded px-3 py-2 text-left text-sm font-semibold text-slate-700',
						]"
					>
						<Image
							v-if="profile?.organisationSymbol"
							:src="profile.organisationSymbol"
							:height="30"
							:width="30"
							class="relative aspect-square h-6 w-6 rounded-full object-cover"
						/>

						<span>Appex</span>

						<Icon
							name="heroicons:check-circle-20-solid"
							class="ml-auto h-5 w-5 text-green-500"
							aria-hidden="hidden"
						/>
					</button>
				</MenuItem>

				<MenuItem v-slot="{ active }">
					<button
						:class="[
							active ? 'bg-slate-100' : '',
							'group flex w-full items-center gap-x-2  rounded px-3 py-2 text-left text-sm text-slate-700',
						]"
					>
						<Image
							v-if="profile?.organisationSymbol"
							:src="profile.organisationSymbol"
							:height="30"
							:width="30"
							class="relative aspect-square h-6 w-6 rounded-full object-cover"
						/>
						<span class="text-slate-500">Grex</span>

						<span
							class="ml-auto text-xs text-slate-400 opacity-0 transition-opacity group-hover:opacity-100"
							>Bytt til</span
						>
					</button>
				</MenuItem>
			</MenuItems>
		</transition>
	</Menu>
	-->
</template>

<script setup lang="ts">
//import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';

const { profile } = useProfile();
</script>
