import { Constructor, UseModalReturnType, useModalSlot } from 'vue-final-modal';
import { LocationQuery } from 'vue-router';
import ModalSave from '~/components/Modal/ModalSave.vue';

interface ActiveSlideOver {
	id: number;
	name: string;
	slideOver: UseModalReturnType<any>;
	component: Constructor;
	confirmBeforeClose: boolean;
}

export default function () {
	const activeSlideOvers = useState<ActiveSlideOver[]>(
		'slideOverRegister',
		() => []
	);

	const hasRunInitialSetup = useState<boolean>('sharedWatcher', () => false);
	const isConfirmDialogOpen = useState<boolean>(
		'isConfirmDialogOpen',
		() => false
	);

	function registerSlideOver(
		id: number,
		name: string,
		slideOver: UseModalReturnType<any>,
		component: Constructor
	) {
		const existingSlideOver = activeSlideOvers.value.find(
			(slideOver) => slideOver.name === name
		);

		if (existingSlideOver) {
			return;
		}

		activeSlideOvers.value.push({
			id,
			name,
			slideOver,
			component,
			confirmBeforeClose: false,
		});
	}

	function removeSlideOver(id: number) {
		const index = activeSlideOvers.value.findIndex(
			(slideOver) => slideOver.id === id
		);

		if (index !== -1) {
			activeSlideOvers.value.splice(index, 1);
		}
	}

	function closeSlideOver(
		id: number,
		options: { clearQuery?: boolean } = { clearQuery: true }
	) {
		const slideOver = activeSlideOvers.value.find(
			(slideOver) => slideOver.id === id
		);

		if (!slideOver) {
			return;
		}

		// Remove each key of the slideOverData from the query
		if (options.clearQuery) {
			const currentQuery = { ...route.query };

			for (const key in currentQuery) {
				delete currentQuery[key];
				delete currentQuery.soid;
			}

			navigateTo({
				query: currentQuery,
			});
		}

		slideOver.slideOver.close();
	}

	function setConfirmClose(id: number, confirmClose: boolean) {
		const slideOver = activeSlideOvers.value.find(
			(slideOver) => slideOver.id === id
		);

		if (slideOver) {
			slideOver.confirmBeforeClose = confirmClose;
		}
	}

	function queryParamsToProps(component: any, queryParams: LocationQuery) {
		const props = component.props as Record<string, any>;

		let finalProps: any = {};

		const query = queryParams;
		for (const queryParam in query) {
			if (props[queryParam]) {
				finalProps[queryParam] = String(query[queryParam]);
			}
		}

		return finalProps;
	}

	const { openModal } = useAsyncModal<boolean>({
		component: ModalSave,
	});

	// Open SlideOver on initial pageload
	const route = useRoute();
	onMounted(() => {
		setTimeout(() => {
			if (!hasRunInitialSetup.value) {
				handleQueryChange(route.query, {});
				hasRunInitialSetup.value = true;
			}
		}, 500);
	});

	const app = useNuxtApp();
	async function handleQueryChange(
		newValue: LocationQuery,
		oldValue: LocationQuery
	): Promise<boolean> {
		if (activeSlideOvers.value.length === 0) {
			// Because of how the Nuxt router works, sometimes the page is not loaded when the middleware runs, and therefore no slideovers are registered.
			// We then wait for the page to be mounted and try again.
			app.hook('page:finish', () => {
				handleQueryChange(newValue, oldValue);
			});

			return true;
		}

		if (newValue === oldValue) {
			return true;
		}

		if (!newValue?.soid && !oldValue?.soid) {
			return true;
		}

		const newName = newValue.soid;

		// When switching from one slideover to another, first close the old one
		if (newName && oldValue?.soid) {
			const oldSlideOver = activeSlideOvers.value.find(
				(slideOver) => slideOver.name === oldValue.soid
			);

			if (oldSlideOver) {
				if (oldSlideOver.confirmBeforeClose) {
					isConfirmDialogOpen.value = true;

					const { value: confirmRes } = await openModal();
					if (!confirmRes) {
						isConfirmDialogOpen.value = false;
						return false;
					}

					oldSlideOver.confirmBeforeClose = false;
					isConfirmDialogOpen.value = false;
				}

				closeSlideOver(oldSlideOver.id, { clearQuery: false });
			}
		}

		if (newName && newName !== oldValue?.soid) {
			const existingSlideOver = activeSlideOvers.value.find(
				(slideOver) => slideOver.name === newName
			);

			if (!existingSlideOver) {
				console.error('SlideOver not found: ', newName);
				return true;
			}

			const slideOverData = queryParamsToProps(
				existingSlideOver.component,
				newValue
			);

			// Set props for slideover
			existingSlideOver.slideOver.patchOptions({
				slots: {
					default: useModalSlot({
						component: existingSlideOver.component,
						attrs: slideOverData,
					}),
				},
			});

			existingSlideOver.slideOver.open();

			return true;
		} else {
			// Close all SlideOvers
			isConfirmDialogOpen.value = false;

			for (const slideOver of activeSlideOvers.value) {
				if (slideOver.confirmBeforeClose) {
					isConfirmDialogOpen.value = true;

					const { value: confirmRes } = await openModal();
					if (!confirmRes) {
						isConfirmDialogOpen.value = false;
						return false;
					}

					slideOver.confirmBeforeClose = false;
					isConfirmDialogOpen.value = false;
				}

				slideOver.slideOver.close();
			}
		}

		return true;
	}

	return {
		isConfirmDialogOpen,
		activeSlideOvers,
		registerSlideOver,
		closeSlideOver,
		setConfirmClose,
		handleQueryChange,
		removeSlideOver,
	};
}
