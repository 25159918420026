export default function () {
	const impersonatingId = useState<string | null>('impersonatingId', () => {
		const storedImpersonatingId = localStorage.getItem('impersonatingId');
		return storedImpersonatingId || null;
	});

	function impersonate(id: string) {
		localStorage.setItem('impersonatingId', id);
		impersonatingId.value = id;
	}

	function stopImpersonating() {
		localStorage.removeItem('impersonatingId');
		impersonatingId.value = null;
	}

	return {
		impersonatingId,
		impersonate,
		stopImpersonating,
	};
}
