import { FormKitNode } from '@formkit/core';

export default function () {
	const form = ref<{ node: FormKitNode<unknown> } | null>(null);

	const isDirty = computed(() => form.value?.node?.context?.state.dirty);
	const isLoading = computed(() => form.value?.node?.context?.state.loading);

	const dirtyEvent = createEventHook<boolean>();

	// Because v-if on fields inside of a FormKit form causes the form to be "dirty",
	// we need to wait a bit before we start watching the form for changes.
	// This is a workaround for that and will not make any impact on the user experience.
	setTimeout(() => {
		watch(isDirty, (v) => dirtyEvent.trigger(v ?? false), {
			immediate: true,
		});
	}, 2000);

	watch(
		isLoading,
		(loading) => {
			if (loading) {
				form.value?.node.clearErrors();
			}
		},
		{
			immediate: true,
		}
	);

	function reset(data?: any) {
		if (form.value) {
			if (data === undefined) {
				const currentValue = form.value.node.context?.value;
				data = currentValue === undefined ? {} : currentValue;
			}

			form.value.node.reset(data);
		}
	}

	function setFormError(error: any) {
		let message = 'En ukjent feil har oppstått';
		if (error?.message) {
			message = $s(error.message);
		}

		form.value?.node.setErrors(message);
	}

	return {
		form,
		isDirty,
		onDirty: dirtyEvent.on as (fn: (dirty: boolean) => void) => void,
		reset,
		setFormError,
	};
}
