import { QueryFunctionContext, QueryKey } from '@tanstack/vue-query';
import { Query } from '~/types/axos-api';

export default function (query: MaybeRef<string>, variables?: MaybeRef<any>) {
	const app = useNuxtApp();

	const queryFn = async (context: QueryFunctionContext<QueryKey>) => {
		try {
			const res = await app.$graphql.request<Query, any>(
				unref(query),
				unref(variables)
			);

			// TODO: Remove casting when `app.$graphql` is correctly typed
			return res as Query;
		} catch (error: any) {
			const errorData = error?.data?.errors?.[0];
			if (errorData) {
				throw errorData;
			} else {
				throw error;
			}
		}
	};

	return queryFn;
}
