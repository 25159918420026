export default function () {
	const config = useRuntimeConfig();
	const { impersonatingId } = useImpersonate();
	const restDefaults = {
		baseURL: config.public.BFF_URL,
		credentials: 'include' as const,
		headers: {
			'Impersonate-Id': impersonatingId.value,
		},
	};

	return { restDefaults };
}
